<template>
  <div class="lottery-wrap">
    <lotteryStarfield />
    <div class="choujiang_div" v-if="is_choujiang1">
      <div class="title">幸运抽奖</div>
      <div class="jp">{{ prize_name }}</div>
      <div class="qh_lw">
        <div class="qh" @click="select_prize_jian">上一个</div>
        <img class="img" src="@/assets/lw.png" />
        <div class="qh" @click="select_prize_jia">下一个</div>
      </div>
      <!-- <div class="jp">{{ prize_name }}</div> -->
      <input v-model="message" id="musicBox" class="input_num" />
      <button @click="lotteryStart" class="start">开始抽奖</button>
    </div>

    <div class="choujiang_div3" v-if="is_choujiang3">
      <div id="musicBox" class="music-box">获奖名单</div>
      <button @click="tohome" class="lottery-stop">回到首页</button>
      <ul class="prize-list">
        <li
          class="prize-item"
          v-for="(item, index) in cardListWinAll"
          :key="index"
        >
          <div class="item">
            <img
              class="img"
              :src="item.wxAvatar == null ? item.avatar.url : item.wxAvatar"
            />
            <div class="name">
              {{ item.name }}
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- <transition name="test" appear> -->
    <div class="lottery-content" v-if="is_choujiang2">
      <Lottery3d />
      <button @click="lotteryStop" class="lottery-stop">停止抽奖</button> 2
    </div>
    <!-- </transition> -->

    <div class="dialog" v-if="err != ''">
      <div class="err">{{ err }}</div>

      <button class="errok" @click="err_ok">确定</button>
    </div>
    <suspenPopup v-if="is_choujiang1"> </suspenPopup>
  </div>
</template>

<style lang="scss" scoped>
.supen-popup {
  justify-content: center;
  align-items: center;
  border-radius: 20rpx;
  box-sizing: border-box;
  background: white;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #aaa;
  font-size: 24rpx;
  width: 50px;
  height: 50px;
}

.test-enter,
.test-leave-to {
  scale: 0;
}

.test-enter-to,
.test-leave {
  scale: 100%;
}

.test-enter-active,
.test-leave-active {
  transition: 3s !important;
}

.choujiang_div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dialog {
  width: 20%;
  height: 20%;
  border: 1px solid black;
  margin: 0px auto;
  position: absolute;
  left: 40%;
  top: 40%;
  background: #000;
  text-align: center;
}
.err {
  font-size: 50px;
  height: 80px;
  line-height: 80px;
}
.errok {
  font-size: 30px;
  margin-top: 20px;
}
.title {
  color: #ff0000;
  font-size: 80px;
  height: 100px;
  margin-top: 100px;
}

.qh {
  color: #ff0000;
  font-size: 30px;
  height: 100px;
  margin-top: 100px;
}
.qh_lw {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.img {
  width: 60px;
  height: 60px;
  margin: 20px;
}
.jp {
  color: #ff0000;
  font-size: 50px;
  height: 60px;
  margin-top: 20px;
}
.input_num {
  width: 100px;
  background: #ff0000;
  height: 50px;
  font-size: 40px;
  border-radius: 10px;
  text-align: center;
}

.start {
  width: 100px;
  height: 50px;
  margin-top: 50px;
  border-radius: 10px;
}
@keyframes rotate360 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}
.choujiang_div3 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.choujiang_left {
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.choujiang_right {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.music-box {
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 50px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  background-color: rgba(253, 105, 0, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.music-box1 {
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 50px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  background-color: rgba(111, 23, 212, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.lottery-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.lottery-header {
  flex: none;
  height: 50px;
  width: 100%;
  background-color: hsla(0, 0%, 100%, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: lightseagreen;
  font-size: 26px;
  font-weight: bold;
  //position: fixed;
}
.lottery-content {
  flex: 1;
  display: flex;
}
.lottery-stop {
  position: fixed;
  top: 20px;
  right: 20px;
}
.prize-list {
  width: 70%;
  left: 15%;
  margin-top: 10%;
  position: fixed;
  z-index: 1000;
  display: inline;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.prize-item {
  float: left;
  margin: 0 auto;
  padding: 10px;
  height: 250px;
  margin-top: 10px;
}

.item {
  width: 200px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.img {
  height: 100px;
  width: 100px;
}
.name {
  width: 200px;
  font-size: 20px;
  color: #fff;
  white-space: normal;
}
</style>

<script>
import { Component, Vue } from "vue-property-decorator";
import Lottery3d from "./lottery-3d.vue";
import lotteryStarfield from "./lottery-starfield.vue";
import lotteryConfig from "./lottery-config.js";
import { get_prize, lottery } from "@/plugins/api.js";

import { cardFlyAnimation, rotateBall, rotateBallStop } from "./3d-action.js";
import STATUS from "./3d-status.js";
import { init, animate, transform } from "./3d.js";
import localStorage from "localStorage";
import { get, post } from "@/plugins/api1.js";
import imgName0 from "@/assets/lw.png";
import suspenPopup from "./code.vue";
import { scene, objects, choujianglist } from "./3d-core.js";

@Component({
  components: {
    Lottery3d,
    lotteryStarfield,
    suspenPopup,
  },
})
export default class Lottery extends Vue {
  headerTitle = lotteryConfig.headerTitle;
  cardListWinAll = lotteryConfig.cardListWinAll;
  // cardListWinAll = [
  //   {
  //     createdAt: "2024-02-16 12:48:22",
  //     updatedAt: "2024-02-16 12:51:58",
  //     deletedAt: null,
  //     id: 320,
  //     memberId: 3,
  //     activityCode: "526eaa0dc4872aece304bfa19f71fe63",
  //     name: "༺破啊啊啊啊啊啊啊啊啊啊晓༻",
  //     avatar: null,
  //     wxAvatar:
  //       "https://thirdwx.qlogo.cn/mmopen/vi_32/yiclC418mfPgKgiaiaTpn9icsVZXIKxHv3q2UPBJMwQWicgIusBsPXQtnNKSdQhn8z57xa6b35Xx2ib4bicwoPLuFu4vT57ob9xaf7R62mN0Fze0oc/132",
  //     fromType: 1,
  //     sex: "0",
  //     isBlack: false,
  //     isForbidden: false,
  //     isExcept: false,
  //     signStatus: true,
  //     ipInfo: "111.227.21.26",
  //     openId: null,
  //     isManager: false,
  //     unionId: "oIFYns2TZBa5c4jQxshA3Tcoj3XI",
  //   },
  //   {
  //     createdAt: "2024-02-22 11:11:02",
  //     updatedAt: "2024-02-22 11:11:02",
  //     deletedAt: null,
  //     id: 337,
  //     memberId: 3,
  //     activityCode: "526eaa0dc4872aece304bfa19f71fe63",
  //     name: "青",
  //     avatar: null,
  //     wxAvatar:
  //       "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJGxtczpu3ibiaxFwEJQBoh5kuuJBQzuGhHNib29wIwkH3DQvwxoDQRY4WeZZ1btKIgNQOB8bLacTygA/132",
  //     fromType: 1,
  //     sex: "0",
  //     isBlack: false,
  //     isForbidden: false,
  //     isExcept: false,
  //     signStatus: true,
  //     ipInfo: "36.98.131.0",
  //     openId: "oRm4aw5qLeNcUGlA-3Wqva29JUR0",
  //     isManager: false,
  //     unionId: "oIFYns9jXijOmCqHWR-2ucSohztk",
  //   },
  //   {
  //     createdAt: "2024-02-21 16:57:23",
  //     updatedAt: "2024-02-21 16:57:23",
  //     deletedAt: null,
  //     id: 280,
  //     memberId: 3,
  //     activityCode: "526eaa0dc4872aece304bfa19f71fe63",
  //     name: "秋深啊啊啊啊啊啊啊啊啊啊啊啊啊一十八",
  //     avatar: null,
  //     wxAvatar:
  //       "https://thirdwx.qlogo.cn/mmopen/vi_32/PB2a87kgfSgoyGLh57ukTDpMH2qRsibQGuVtpjPeJOV2FtyNZ3yuKNuVTbwOZj29oJE3IH8KJq4R22ssonJEnO5YLkXrvgBf1kP1c7EhDmEk/132",
  //     fromType: 1,
  //     sex: "0",
  //     isBlack: false,
  //     isForbidden: false,
  //     isExcept: false,
  //     signStatus: true,
  //     ipInfo: "27.128.49.204",
  //     openId: "oRm4aw7cV8CCzIgkixeYO53awfho",
  //     isManager: false,
  //     unionId: "oIFYns77e7riP4D4B468uc-cpxoU",
  //   },
  //   {
  //     createdAt: "2024-02-22 11:10:47",
  //     updatedAt: "2024-02-22 11:10:47",
  //     deletedAt: null,
  //     id: 283,
  //     memberId: 3,
  //     activityCode: "526eaa0dc4872aece304bfa19f71fe63",
  //     name: "吴爽",
  //     avatar: null,
  //     wxAvatar:
  //       "https://thirdwx.qlogo.cn/mmopen/vi_32/ic2M2l0LdpMy0XZCwVAQtAqMHzO2ukhicuickcp1jWBausGxl5wUib06GM5sNkxvMR4nujEF66361biapwI1N1YjsmA/132",
  //     fromType: 1,
  //     sex: "0",
  //     isBlack: false,
  //     isForbidden: false,
  //     isExcept: false,
  //     signStatus: true,
  //     ipInfo: "106.117.232.49",
  //     openId: "oRm4awxaHnzdgwPzB-y0JQcb4oXQ",
  //     isManager: false,
  //     unionId: "oIFYnsxgc-D0xppNo45V1ZuHWj_Y",
  //   },
  //   {
  //     createdAt: "2024-03-01 08:40:57",
  //     updatedAt: "2024-03-01 08:40:57",
  //     deletedAt: null,
  //     id: 457,
  //     memberId: 3,
  //     activityCode: "526eaa0dc4872aece304bfa19f71fe63",
  //     name: "吴爽",
  //     avatar: null,
  //     wxAvatar:
  //       "https://thirdwx.qlogo.cn/mmopen/vi_32/ic2M2l0LdpMy0XZCwVAQtAqMHzO2ukhicuickcp1jWBausGxl5wUib06GM5sNkxvMR4nujEF66361biapwI1N1YjsmA/132",
  //     fromType: 1,
  //     sex: "0",
  //     isBlack: false,
  //     isForbidden: false,
  //     isExcept: false,
  //     signStatus: true,
  //     ipInfo: "27.190.154.92",
  //     openId: "oRm4awxaHnzdgwPzB-y0JQcb4oXQ",
  //     isManager: false,
  //     unionId: "oIFYnsxgc-D0xppNo45V1ZuHWj_Y",
  //   },
  //   {
  //     createdAt: "2024-02-21 16:56:56",
  //     updatedAt: "2024-02-21 16:56:56",
  //     deletedAt: null,
  //     id: 88,
  //     memberId: 3,
  //     activityCode: "526eaa0dc4872aece304bfa19f71fe63",
  //     name: "青",
  //     avatar: null,
  //     wxAvatar:
  //       "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJGxtczpu3ibiaxFwEJQBoh5kuuJBQzuGhHNib29wIwkH3DQvwxoDQRY4WeZZ1btKIgNQOB8bLacTygA/132",
  //     fromType: 1,
  //     sex: "0",
  //     isBlack: false,
  //     isForbidden: false,
  //     isExcept: false,
  //     signStatus: true,
  //     ipInfo: "27.128.31.136",
  //     openId: "oRm4aw5qLeNcUGlA-3Wqva29JUR0",
  //     isManager: false,
  //     unionId: "oIFYns9jXijOmCqHWR-2ucSohztk",
  //   },
  //   {
  //     createdAt: "2024-02-22 11:10:49",
  //     updatedAt: "2024-02-22 11:10:49",
  //     deletedAt: null,
  //     id: 388,
  //     memberId: 3,
  //     activityCode: "526eaa0dc4872aece304bfa19f71fe63",
  //     name: "兰摧玉折",
  //     avatar: null,
  //     wxAvatar:
  //       "https://thirdwx.qlogo.cn/mmopen/vi_32/eQkpsZXh1Qq2ibrLCSMQicdMT0cT7TPXgZGn7cfnVK37akhz63zxO5dUZNqVOhK9nlHOiaGcDBDKPfSm1ANDHfJew/132",
  //     fromType: 1,
  //     sex: "0",
  //     isBlack: false,
  //     isForbidden: false,
  //     isExcept: false,
  //     signStatus: true,
  //     ipInfo: "106.117.232.49",
  //     openId: "oRm4awzi-RQIusdTkL-d6aUEwurY",
  //     isManager: false,
  //     unionId: "oIFYns8au5wI-rgs5WrnUQCYw-0I",
  //   },
  //   {
  //     createdAt: "2024-03-03 17:58:54",
  //     updatedAt: "2024-03-03 17:58:54",
  //     deletedAt: null,
  //     id: 121,
  //     memberId: 3,
  //     activityCode: "526eaa0dc4872aece304bfa19f71fe63",
  //     name: "吉祥如意坤泰葆总部全国招商",
  //     avatar: null,
  //     wxAvatar:
  //       "https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIynHFEfUu56MxibEwAFTiczZqP0toVr8QXqohKCTfeQ3ucdAiafL7KIw9HB5eIsdnfyibeIIxSXSNWuw/132",
  //     fromType: 1,
  //     sex: "0",
  //     isBlack: false,
  //     isForbidden: false,
  //     isExcept: false,
  //     signStatus: true,
  //     ipInfo: "36.98.18.6",
  //     openId: "oRm4aw57MCrd3pw8x3aemSI-bv3A",
  //     isManager: false,
  //     unionId: "oIFYns_k648e_sg0fUaL9Sn-KqqM",
  //   },
  //   {
  //     createdAt: "2024-02-21 16:57:23",
  //     updatedAt: "2024-02-21 16:57:23",
  //     deletedAt: null,
  //     id: 205,
  //     memberId: 3,
  //     activityCode: "526eaa0dc4872aece304bfa19f71fe63",
  //     name: "秋深一十八",
  //     avatar: null,
  //     wxAvatar:
  //       "https://thirdwx.qlogo.cn/mmopen/vi_32/PB2a87kgfSgoyGLh57ukTDpMH2qRsibQGuVtpjPeJOV2FtyNZ3yuKNuVTbwOZj29oJE3IH8KJq4R22ssonJEnO5YLkXrvgBf1kP1c7EhDmEk/132",
  //     fromType: 1,
  //     sex: "0",
  //     isBlack: false,
  //     isForbidden: false,
  //     isExcept: false,
  //     signStatus: true,
  //     ipInfo: "27.128.49.204",
  //     openId: "oRm4aw7cV8CCzIgkixeYO53awfho",
  //     isManager: false,
  //     unionId: "oIFYns77e7riP4D4B468uc-cpxoU",
  //   },
  //   {
  //     createdAt: "2024-02-22 11:12:32",
  //     updatedAt: "2024-02-22 11:12:32",
  //     deletedAt: null,
  //     id: 159,
  //     memberId: 3,
  //     activityCode: "526eaa0dc4872aece304bfa19f71fe63",
  //     name: "My_king-Arthur",
  //     avatar: null,
  //     wxAvatar:
  //       "https://thirdwx.qlogo.cn/mmopen/vi_32/3RgGlXW9F0Sx8QG5V6Nu9IyR5ymeIKKUQRoDFB2K3pK4NbiausrMRNsWE2iaCDO853QM1HTjkHjEflhdEMdIWYtw/132",
  //     fromType: 1,
  //     sex: "0",
  //     isBlack: false,
  //     isForbidden: false,
  //     isExcept: false,
  //     signStatus: true,
  //     ipInfo: "39.144.85.89",
  //     openId: "oRm4awy6FOg6aqsLE02mcVVCC3a0",
  //     isManager: false,
  //     unionId: "oIFYnswl6moiDXXSrmuywCtmoJ38",
  //   },
  // ];
  is_choujiang1 = true;
  is_choujiang2 = false;
  is_choujiang3 = false;
  message = 1;
  prizeList = [];
  prize = -1;
  prize_name = "";
  select = 0;
  activityCode = "";
  err = "";
  async mounted() {
    console.log(`the component is now mounted.`);

    console.log("当前路由信息", window.location.href.split("?")[1]);

    this.activityCode = new URLSearchParams(
      window.location.href.split("?")[1]
    ).get("activityCode"); // "John"

    console.log("activityCode", this.activityCode);

    // if (this.activityCode != "") {
    //   window.localStorage.setItem("activityCode", this.activityCode);
    // }

    get_prize({
      lotteryType: "THREE_D",
      activityCode: this.activityCode,
    }).then((res) => {
      // console.log(res.data);
      // console.log("发送请求了");

      if (
        res.data.code == "SUCCESS" &&
        res.data.payload.list != [] &&
        res.data.payload.list.length > 0
      ) {
        this.prizeList = res.data.payload.list;
        this.prize_name = this.prizeList[this.select].prizeName;
        this.prize = this.prizeList[this.select].id;
      } else {
        this.err = "获取奖品失败";
      }
    });
  }
  async err_ok() {
    this.err = "";
  }
  async lotteryStart() {
    // lottery().then((res) => {
    //   console.log(res.data);
    // });

    post("/lottery", {
      activityCode: this.activityCode,
      lotteryType: "THREE_D",
      prizeId: this.prize,
      pickNum: this.message,
    })
      .then((response) => {
        console.log(response);
        if (response.code == "SUCCESS") {
          lotteryConfig.num = this.message;
          this.is_choujiang1 = false;
          this.is_choujiang2 = true;
          this.is_choujiang3 = false;
          this.cardListWinAll = response.payload.list;
        } else {
          this.err = response.message;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async lotteryStop() {
    this.$bus.$emit("stop");
    // this.cardListWinAll = lotteryConfig.cardListWinAll;
    console.log(this.cardListWinAll);
    this.is_choujiang1 = false;
    this.is_choujiang2 = false;
    this.is_choujiang3 = true;

    // objects = [];
    // choujianglist = [];
  }

  async tohome() {
    this.is_choujiang1 = true;
    this.is_choujiang2 = false;
    this.is_choujiang3 = false;
    this.$router.go(0); // 刷新当前页面
  }

  async select_prize_jia() {
    if (this.prizeList != [] && this.select < this.prizeList.length - 1) {
      this.select++;
      this.prize_name = this.prizeList[this.select].prizeName;
      this.prize = this.prizeList[this.select].id;
    }
  }

  async select_prize_jian() {
    if (this.prizeList != [] && this.select > 0) {
      this.select--;
      this.prize_name = this.prizeList[this.select].prizeName;
      this.prize = this.prizeList[this.select].id;
    }
  }

  onChange(e) {
    console.log(e);
  }
}
</script>
