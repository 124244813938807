<template>
  <div class="lottery-3d-wrap">
    <div id="container"></div>
    <!-- 
    <ul class="prize-list">
      <li
        class="prize-item"
        v-for="(item, index) in zhongjiang_list"
        :key="index"
      >
        <div class="item">
          <img class="img" :src="item.avatar" />

          {{ item.name }}
        </div>
      </li>
    </ul> -->

    <div class="prize-list">
      <div
        class="prize-item"
        v-for="(item, index) in zhongjiang_list"
        :key="index"
      >
        <div class="item">
          <img
            class="img"
            :src="item.wxAvatar == null ? item.avatar.url : item.wxAvatar"
          />
          <div class="name">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.lottery-3d-wrap {
  height: 100%;
  width: 100%;
}
#container {
  height: 100%;
  width: 100%;
}
.prize-list {
  width: 800px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: #000;
  opacity: 0.75;
  text-align: center;
}
.prize-item {
  list-style: none;
  display: inline-block;
  padding: 10px;
}
.item {
  width: 100px;
  height: 130px;
  display: flex;
  flex-direction: column;
  color: #fff000;
  align-items: center;
}
.img {
  height: 80px;
  width: 80px;
}
.name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-climp: 4;
  overflow: hidden;
  height: 50px;
}
</style>

<script>
// https://threejs.org/examples/css3d_periodictable.html
import { Component, Vue } from "vue-property-decorator";
import "./origin-main.css";
import "./origin-periodictable.css";
import "./lottery-custom.css";
import { init, animate, transform } from "./3d.js";
import { cardFlyAnimation, rotateBall, rotateBallStop } from "./3d-action.js";
import lotteryStarfield from "./lottery-starfield.vue";
import localStorage from "localStorage";
import { get3 } from "@/plugins/api.js";
import lotteryConfig from "./lottery-config.js";
const { cardList, colCount, rowCount } = lotteryConfig;
import lotteryConfigUsersRawJson from "./lottery-config-users-raw.json";
const cardUserList = lotteryConfigUsersRawJson;

@Component({
  components: {},
})
export default class Lottery3D extends Vue {
  name = "";
  avatar = "";
  num = 0;
  list = [];
  zhongjiang_list = [];
  timer;

  activityCode = "";

  async mounted() {
    // console.log(`the component is now mounted.`);

    // console.log("当前路由信息", window.location.href.split("?")[1]);

    this.activityCode = new URLSearchParams(
      window.location.href.split("?")[1]
    ).get("activityCode"); // "John"

    // console.log("activityCode", this.activityCode);
    get3({
      activityCode: this.activityCode,
      page: -1,
      size: -1,
    }).then((res) => {
      // console.log(res.data);
      // console.log("发送请求了");

      // this.list = res.data.payload.list;
      for (let j = 0; j < 200 / res.data.payload.list.length; j++) {
        for (let i in res.data.payload.list) {
          this.list.push(res.data.payload.list[i]);
        }
      }
      lotteryConfig.cardList = this.list;
      console.log("1数量" + this.list.length);
      if (this.list.length> 200) this.list = this.list.splice(0, 200);
      init(this.list);
      animate();
      transform("sphere", 0); // sphere
      this.$bus.$emit("lottery-3d-init");
      rotateBall();

      this.timer = setInterval(() => {
        let radom = this.getRandomNumber(
          lotteryConfig.num,
          0,
          this.list.length
        );

        for (let i = 0; i < radom.length; i++) {
          if (this.zhongjiang_list.length == lotteryConfig.num) {
            this.$set(this.zhongjiang_list, i, this.list[radom[i]]);
          } else {
            this.zhongjiang_list.push(this.list[radom[i]]);
          }
        }
        lotteryConfig.cardListWinAll = this.zhongjiang_list;

        // console.log(
        //   this.getRandomNumber(lotteryConfig.num, 0, this.list.length)
        // );
      }, 1000 * 0.1); // 每隔1秒执行一次
      // });
      this.$bus.$on("stop", () => {
        clearInterval(this.timer);
      });
    });
  }
  // 指定范围内，指定个数，无重复的随机整数

  getRandomNumber(n, min, max) {
    let arr = [];
    for (let i = 0; i < n; i++) {
      arr[i] = parseInt(Math.random() * (max - min + 1) + min);
    }
    for (let i = 0; i < n; i++) {
      for (let j = i + 1; j < n; j++) {
        if (arr[i] === arr[j]) {
          this.getRandomNumber(n, min, max);
          return false;
        }
      }
    }
    return arr;
  }
}
</script>
